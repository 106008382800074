import { BellOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { User } from '@supabase/supabase-js';
import { Flex, Layout, Space } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { useUserData } from 'modules/supabase/utils/hooks/useSupabaseUser';
import { routeStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';

import { ButtonWithTooltips } from 'ui';
import { Changelog } from '../Changelog/Changelog';

import './Header.scss';

import { AppSearch } from './components';

interface HeaderProps extends React.PropsWithChildren {
    text?: string;
    toggleMenuButton?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    style?: React.CSSProperties;
    showUserProfile?: boolean;
    user: User | null;
    setUser: (user: User | null) => void;
}

export const Header = memo<HeaderProps>(
    ({ toggleMenuButton, breadcrumbs, children, style, user, setUser, showUserProfile = true }) => {
        const { infoUser } = useUserData();
        const { t, i18n } = useTranslation();
        const navigate = useStoreNavigate();

        const handleCheckProfile = () => {
            navigate(`administration/users/${infoUser?.Id}`);
        };

        const handleLogout = async () => {
            setUser(null);
            routeStore.clear();
            metaStore.clear();
            await supabaseClient.auth.signOut();
        };

        if (!user) return null;

        const userName =
            infoUser?.Name?.[i18n.language === 'tech' ? 'en' : i18n.language] || infoUser?.Email;

        return (
            <Layout.Header className="header" style={style}>
                <div className="header__container">
                    <div className="header__left">
                        {toggleMenuButton}

                        <AppSearch />

                        {breadcrumbs && <div className="header__breadcrumbs">{breadcrumbs}</div>}
                    </div>
                    <div className="header__content">{children}</div>

                    <Flex gap={5} align="center" justify="center" className="header__right">
                        <Space.Compact>
                            {showUserProfile && (
                                <ButtonWithTooltips
                                    id="profile"
                                    tooltipTitle={t('profile')}
                                    type="text"
                                    className="header__profile"
                                    icon={<UserOutlined />}
                                    onClick={handleCheckProfile}
                                >
                                    {userName}
                                </ButtonWithTooltips>
                            )}
                            <LanguageSwitcher />
                            {/* <Badge> */}
                            <ButtonWithTooltips
                                id="notifications"
                                tooltipTitle={t('notifications')}
                                className=""
                                type="text"
                                icon={<BellOutlined style={{ fontSize: 18 }} />}
                            />
                            {/* </Badge> */}

                            <Changelog />

                            <ButtonWithTooltips
                                id="logout"
                                tooltipTitle={t('logout')}
                                tooltipPlacement="bottomLeft"
                                className="header__logout"
                                type="text"
                                icon={<LogoutOutlined />}
                                onClick={handleLogout}
                            />
                        </Space.Compact>
                    </Flex>
                </div>
            </Layout.Header>
        );
    }
);
