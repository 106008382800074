import { Button, Menu, MenuProps, Space } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'antd-mobile';
import { useBoolean } from 'react-use';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

interface MobileCardToolbarProps {
    actionsMenuItems: MenuItem[];
    reportsMenuItems: MenuItem[];
    followsMenuItems: MenuItem[];
}

export const MobileCardToolbar = memo<MobileCardToolbarProps>(
    ({ actionsMenuItems, followsMenuItems, reportsMenuItems }) => {
        const { t } = useTranslation();

        const [actionsPopup, setActionsPopup] = useBoolean(false);
        const [reportsPopup, setReportsPopup] = useBoolean(false);
        const [followsPopup, setFollowsPopup] = useBoolean(false);

        return (
            <>
                <Space.Compact block>
                    {actionsMenuItems.length ? (
                        <Button block onClick={() => setActionsPopup(true)}>
                            {t('actions')}
                        </Button>
                    ) : null}
                    {reportsMenuItems.length ? (
                        <Button block onClick={() => setReportsPopup(true)}>
                            {t('reports')}
                        </Button>
                    ) : null}
                    {followsMenuItems.length ? (
                        <Button block onClick={() => setFollowsPopup(true)}>
                            {t('follows')}
                        </Button>
                    ) : null}
                </Space.Compact>

                <Popup
                    visible={actionsPopup}
                    onMaskClick={() => setActionsPopup(false)}
                    onClose={() => setActionsPopup(false)}
                    bodyStyle={{ height: 'max-content' }}
                >
                    <Menu
                        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
                        mode="inline"
                        onClick={() => {
                            setActionsPopup(false);
                        }}
                        items={actionsMenuItems}
                    />
                </Popup>
                <Popup
                    visible={reportsPopup}
                    onMaskClick={() => setReportsPopup(false)}
                    onClose={() => setReportsPopup(false)}
                    bodyStyle={{ height: 'max-content' }}
                >
                    <Menu
                        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
                        mode="inline"
                        onClick={() => {
                            setReportsPopup(false);
                        }}
                        items={reportsMenuItems}
                    />
                </Popup>
                <Popup
                    visible={followsPopup}
                    onMaskClick={() => setFollowsPopup(false)}
                    onClose={() => setFollowsPopup(false)}
                    bodyStyle={{ height: 'max-content' }}
                >
                    <Menu
                        style={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
                        mode="inline"
                        onClick={() => {
                            setFollowsPopup(false);
                        }}
                        items={followsMenuItems}
                    />
                </Popup>
            </>
        );
    }
);
