import { CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { Dropdown, Flex, Layout, MenuProps, Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ButtonWithTooltips } from 'ui';
import { routeStore, RouteStoreItemType, useStoreNavigate } from 'utils/store';

import { toJS } from 'mobx';
import { useAliveController } from 'react-activation';
import { getTableNameByPath } from 'utils';
import { detailPageStore } from 'utils/store/DetailPageStore/DetailPageStore';
import { ID_REGX, USER_ID_REGX } from '../constants';
import './Footer.scss';
import { isEqual } from 'lodash';
import { isDefined } from 'is-lite/exports';

interface FooterProps {
    style?: React.CSSProperties;
}

interface ChipLabelType {
    route: RouteStoreItemType;
    contextMenuItems: MenuProps['items'];
}

const ChipLabel = memo<ChipLabelType>(({ route, contextMenuItems }) => {
    const { t } = useTranslation();
    const spanRef = useRef<HTMLSpanElement>(null);

    const [disableTooltip, setDisableTooltip] = useState(false);

    const renderChipContent = useCallback(
        (data: string, state?: any) => {
            const titleArr = data.split('/');

            const title =
                state && state.pageTitle ? state.pageTitle : titleArr[titleArr.length - 1];

            const isTitleId = /^\d+$/.test(title);

            const isTitleUserId =
                /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(title);

            const isCreate = title.includes('?mode=create');

            if (isTitleId || isTitleUserId)
                return `${isTitleUserId ? title.split('-')[0] : title} (${t(
                    titleArr[titleArr.length - 2]
                )})`;

            if (isCreate) return `${t('creation')} (${t(titleArr[titleArr.length - 2])})`;

            const isTitleHasObjectStruct = t(title).includes('returned an object');

            return isTitleHasObjectStruct ? t(`${title}.title`) : t(title) || t('home');
        },
        [t]
    );

    return (
        <Dropdown
            onOpenChange={(open) => setDisableTooltip(open)}
            trigger={['contextMenu']}
            menu={{ items: contextMenuItems }}
            destroyPopupOnHide
        >
            <Tooltip
                open={
                    disableTooltip ||
                    spanRef?.current?.clientWidth === spanRef?.current?.scrollWidth
                        ? false
                        : undefined
                }
                mouseEnterDelay={0.7}
                destroyTooltipOnHide
                title={renderChipContent(`${route.pathname}${route.search || ''}`, route.state)}
            >
                <Flex className="footer__chip" justify="space-between">
                    <Flex align="center" gap={5}>
                        {route.pathname === '/' ? (
                            <HomeOutlined className="footer__chip_home" />
                        ) : undefined}
                        <span
                            ref={spanRef}
                            style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {renderChipContent(
                                `${route.pathname}${route.search || ''}`,
                                route.state
                            )}
                        </span>
                    </Flex>
                </Flex>
            </Tooltip>
        </Dropdown>
    );
});

export const Footer = observer<FooterProps>(({ style }) => {
    const { t } = useTranslation();
    const navigate = useStoreNavigate();
    const { pathname, search, state } = useLocation();
    const { dropScope, clear: dropAllScope } = useAliveController();

    const handleClear = useCallback(() => {
        routeStore.clear();
        detailPageStore.clear();
        navigate({ pathname: '/', search: '' }, { state: null });

        // Не показывает главную, из-за этой функции
        // dropAllScope();
    }, [navigate]);

    const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;

    const isBookingSlotsPage = pathnameLastElem === 'resource-planner';
    const isDetailPage =
        ID_REGX.test(pathnameLastElem) ||
        USER_ID_REGX.test(pathnameLastElem) ||
        pathnameLastElem === ':' ||
        pathnameLastElem === 'new';

    const handleDropScope = useCallback(() => {
        let scope = `tablePage_${state?.cacheKey || pathname}`;

        if (isDetailPage) scope = `detailPage_${state?.cacheKey || pathname}`;
        else if (isBookingSlotsPage) scope = `resourcePlanner_${state?.cacheKey || pathname}`;

        dropScope(scope);
    }, [dropScope, isDetailPage, isBookingSlotsPage, state?.cacheKey, pathname]);

    const handleClickChip = useCallback(
        (jsonValue: string) => {
            const { pathname, search, state } = JSON.parse(jsonValue);

            navigate(
                { pathname, search },
                {
                    state: state ? toJS(state) : null
                }
            );
        },
        [navigate]
    );

    const handleCloseChip = useCallback(
        (route: RouteStoreItemType) => {
            const currentRouteIndex = routeStore.routes.findIndex((r) =>
                isEqual(r, { pathname, search, state })
            );

            if (currentRouteIndex !== -1 && isEqual(route, { pathname, search, state })) {
                if (isDefined(routeStore.routes[currentRouteIndex + 1])) {
                    const { pathname, state, search } = routeStore.routes[currentRouteIndex + 1];

                    navigate({ pathname, search: search || '' }, { state: toJS(state) });
                } else {
                    const { pathname, state, search } = routeStore.routes[currentRouteIndex - 1];

                    navigate({ pathname, search: search || '' }, { state: toJS(state) });
                }
            }
            handleDropScope();
            detailPageStore.removeCreateInfo(getTableNameByPath(route.pathname));
            routeStore.remove(route);
        },
        [handleDropScope, navigate, pathname]
    );

    // console.log('Footer Tabs', toJS(routeStore.routes), { pathname, search, state });

    return (
        <Layout.Footer className="footer" style={style}>
            <Flex className="footer__container" justify="flex-start">
                <Tabs
                    className="footer__chip_list"
                    type="editable-card"
                    hideAdd
                    onChange={handleClickChip}
                    defaultActiveKey={JSON.stringify({ pathname, search, state })}
                    activeKey={JSON.stringify({ pathname, search, state })}
                    onEdit={(targetKey, action) => {
                        if (action === 'remove') {
                            const route = JSON.parse(targetKey as string);
                            handleCloseChip(route);
                        }
                    }}
                    items={routeStore.routes.map((route, index, array) => {
                        return {
                            key: JSON.stringify(route),
                            label: (
                                <ChipLabel
                                    route={route}
                                    contextMenuItems={[
                                        {
                                            key: 'close',
                                            label: t('close'),
                                            onClick: () => handleCloseChip(route),
                                            disabled: route.pathname === '/'
                                        },
                                        {
                                            key: 'close_all',
                                            label: t('close_all'),
                                            onClick: handleClear,
                                            disabled:
                                                array.length === 1 && array[0].pathname === '/'
                                        },
                                        {
                                            key: 'close_other',
                                            label: t('close_other'),
                                            onClick: () => {
                                                handleClear();
                                                handleClickChip(JSON.stringify(route));
                                            },
                                            disabled:
                                                ((array.length === 2 && index === 1) ||
                                                    array.length === 1) &&
                                                array[0].pathname === '/'
                                        }
                                    ]}
                                />
                            ),
                            value: JSON.stringify(route),
                            closable: route.pathname !== '/'
                        };
                    })}
                />

                <ButtonWithTooltips
                    className="footer__clear"
                    type="text"
                    id="clear-chips"
                    onClick={handleClear}
                    icon={<CloseOutlined />}
                />
            </Flex>
        </Layout.Footer>
    );
});
