import { Divider, Modal } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MetaField } from 'modules/services/backend-api/generated_info';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { SmartDetailPageMainTabContent } from 'smart/modules/SmartDetailPage/components';

import { FieldsBox, FieldsBoxItemType } from '../../../../modules/SmartDetailPage/ui';
import { fieldEditRender, fieldRender } from '../../../../utils';
import { LANGUAGES } from '../../../../../utils/i18n/i18n';

interface RowModalProps {
    open: boolean;
    data: any;
    mode: 'edit' | 'view';
    setData: (data: any) => void;
    onClose: () => void;
    onOk: () => void;
    metaFields?: MetaField[];
    rootMeta?: string;
    rootDataSource?: IObjectWithId;
}

export const RowModal: React.FC<RowModalProps> = ({
    open,
    data,
    metaFields,
    setData,
    onClose,
    onOk,
    mode,
    rootMeta
    // rootDataSource
}) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [modalTitle, setModalTitle] = useState(t(mode));
    useEffect(() => {
        (async () => {
            if (metaFields?.length) {
                const metaCode = metaFields?.[0]?.Meta_Code;
                let metaInfo = metaStore.meta.get(metaCode ?? '')?.info;

                if (metaCode && !metaInfo) {
                    metaInfo = await metaStore.getInfo(metaCode);
                }

                setModalTitle(metaInfo?.SingularName?.[language] ?? t(metaInfo?.TableName));
            } else setModalTitle(t(mode));
        })();
    }, [language, metaFields, mode, t]);

    const handleDataSourceChange = useCallback(
        (key: string, value: any) => {
            // const keys = key.split('.');
            // if (keys.length > 1) {
            //     setData((prevData: any) => ({
            //         ...prevData,
            //         [keys[0]]: { ...prevData[keys[0]], [keys[1]]: value }
            //     }));
            // } else {
            setData((prevData: any) => ({ ...prevData, [key]: value }));
            // }
        },
        [setData]
    );

    const topFields: FieldsBoxItemType[] = useMemo(() => {
        const topFields: FieldsBoxItemType[] = [];

        for (const field of metaFields ?? []) {
            const key = field.FieldName;
            const isId = key === 'Id';

            if (!isId && field.LayoutArea && field.LayoutArea === 'Page/Header') {
                const label = field.Name
                    ? field.Name[language] || t(field.ColumnName || key)
                    : t(field.ColumnName || key);

                const children =
                    !isId && !field.IsReadOnly && mode === 'edit' // default
                        ? fieldEditRender({
                              data: data[key],
                              language,
                              metaFieldData: field,
                              dataSource: data,
                              rootDataSource: data,
                              onChange: handleDataSourceChange,
                              rootMeta
                          })
                        : fieldRender({
                              data: data[key],
                              language: language as LANGUAGES,
                              metaFieldData: field,
                              dataSource: data,
                              rootDataSource: data,
                              rootMeta
                          });
                topFields.push({
                    key,
                    label,
                    children,
                    description: field.Description?.[language]
                });
            }
        }

        return topFields;
    }, [metaFields, language, t, mode, data, handleDataSourceChange, rootMeta]);

    return (
        <Modal
            title={modalTitle}
            centered
            open={open}
            onCancel={onClose}
            onOk={onOk}
            destroyOnClose
            width={'90%'}
        >
            <FieldsBox items={topFields} size="small" />
            <Divider style={{ margin: '5px 1px' }} />
            <SmartDetailPageMainTabContent
                metaFields={
                    metaFields?.filter((mf) => !mf.LayoutArea?.includes('Page/Header')) ?? []
                }
                data={data}
                setData={setData}
                onChange={handleDataSourceChange}
                mode={mode}
                childNodeMeta={[]}
                rootMeta={rootMeta}
                exclude={[]}
            />
        </Modal>
    );
};
