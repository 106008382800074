import { Tooltip, TooltipProps } from 'antd';
import { useBoolean, useUpdateEffect } from 'react-use';
import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';

const ellipsisDefaultStyle: React.CSSProperties = {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all'
};

export const SmartTooltip: React.FC<
    React.PropsWithChildren<{
        style?: React.CSSProperties;
        // trigger?: TooltipProps['trigger'];
        title?: TooltipProps['title'];
        open?: boolean;
    }>
> = ({ children, style, title, open }) => {
    const [hasOverflowingChildren, setHasOverflowingChildren] = useBoolean(false);
    const [text, setText] = useState();
    const [prevPropsChildren, setPrevPropsChildren] = useState(children);

    useUpdateEffect(() => {
        if (!isEqual(children, prevPropsChildren)) {
            setHasOverflowingChildren(false);
            setPrevPropsChildren(children);
        }
    }, [children]);

    const updateOverflow = useCallback<React.EventHandler<any>>(
        (e) => {
            const el = e.target;

            if (!hasOverflowingChildren && el.scrollWidth > el.clientWidth) {
                setHasOverflowingChildren(true);

                if (el.textContent !== text) {
                    setText(el.textContent);
                }
            } else {
                setHasOverflowingChildren(false);
            }
        },
        [hasOverflowingChildren, setHasOverflowingChildren, text]
    );

    const ellipsisStyle = { ...ellipsisDefaultStyle, ...style };

    return hasOverflowingChildren ? (
        <Tooltip
            open={open}
            title={(title as React.ReactNode) ?? children}
            overlayStyle={{ maxWidth: '500px' }}
            // placement="bottomRight"
            // trigger={trigger}
            destroyTooltipOnHide
            trigger="hover"
            mouseEnterDelay={0.5}
        >
            <div title="" style={ellipsisStyle}>
                {children}
            </div>
        </Tooltip>
    ) : (
        <div title={''} style={ellipsisStyle} onMouseEnter={updateOverflow}>
            {children}
        </div>
    );
};
