import { Typography } from 'antd';
import { forwardRef, memo } from 'react';
import { LinkProps } from 'react-router-dom';
import { useStoreNavigate } from 'utils/store';

interface StoreLinkProps extends LinkProps {
    ref?: React.LegacyRef<HTMLElement | null>;
    key?: React.Key;
}

export const StoreLink = memo<StoreLinkProps>(
    forwardRef<HTMLElement | null, StoreLinkProps>(
        (
            { to, state, children, replace, preventScrollReset, relative, style, className, key },
            ref
        ) => {
            const storeNavigate = useStoreNavigate();

            const handleClick = () => {
                storeNavigate(to, { state, replace, preventScrollReset, relative });
            };

            return (
                <Typography.Link
                    onClick={handleClick}
                    ref={ref}
                    style={style}
                    className={className}
                    key={key}
                    ellipsis
                >
                    {children}
                </Typography.Link>
            );
        }
    )
);
