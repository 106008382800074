import { CloseOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageHeader } from 'components';
import { ButtonWithTooltips } from 'ui';
import { routeStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { ID_REGX, USER_ID_REGX } from '../constants';

interface CommonPageProps extends React.PropsWithChildren {
    dropScope: () => void;
    pathnameLastElem: string;
    pathnameLastElemForDetail: string;
    isDetailPage?: boolean;
}

interface CommonPageContextProps {
    getClosePageHandler: (
        location: Location,
        navigate: NavigateFunction,
        dropScope: () => void,
        additionalHandler?: () => void
    ) => () => void;
}

const getClosePageHandler =
    (
        location: Location,
        navigate: NavigateFunction,
        dropScope: () => void,
        additionalHandler?: () => void
    ) =>
    () => {
        if (additionalHandler) additionalHandler();

        const { pathname, state } = location;

        const closedIndex = routeStore.findFirstIndexByPathname(pathname);
        const route = toJS(routeStore.routes[closedIndex - 1]);

        navigate({ pathname: route.pathname, search: route.search || '' }, { state: route.state });

        const routesCopy = routeStore.routes;
        routeStore.clear();
        routesCopy.forEach((route, index) => {
            if (index === closedIndex) {
                return;
            }

            routeStore.push(route);
        });

        dropScope();
    };

export const CommonPageContext = React.createContext<CommonPageContextProps>({
    getClosePageHandler
});

export const CommonPage = memo<CommonPageProps>(({ dropScope, pathnameLastElem, children }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const navigate = useStoreNavigate();
    const oldNavigate = useNavigate();
    const id = useParams();
    const { pathname, search, state } = useLocation();
    const location = useLocation();

    const isHomePage = pathname === '/';
    const isDetailPage =
        ID_REGX.test(pathnameLastElem) ||
        USER_ID_REGX.test(pathnameLastElem) ||
        pathnameLastElem === 'new' ||
        pathnameLastElem === 'copy';

    const [selectedLanguage, setSelectedLanguage] = useState('ru');

    useEffect(() => {
        if (state?.pageTitle) return;

        if (
            !isDetailPage &&
            !isHomePage &&
            !pathname.includes('login') &&
            !pathname.includes('signup')
        ) {
            const metaRoute = metaStore.meta.get('all')?.routes?.find((route) => {
                return (
                    route.path ===
                    `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                );
                // return route.path === pathname;
            });

            const metaCode = metaRoute?.meta;

            const metaMenuItem = metaStore.meta.get('all')?.menu?.items.find((item) => {
                // console.log(
                //     item.path,
                //     `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                // );
                return (
                    item.path ===
                    `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                );
            });

            let pageTitle = '';
            let pluralName;

            if (metaCode) {
                pluralName =
                    metaRoute?.layout?.Info?.Info?.PluralName ||
                    toJS(metaStore.meta.get(metaCode)?.info)?.PluralName;
            }

            const metaMenuName = metaMenuItem?.name?.[language];

            // console.log(metaMenuName, pluralName?.[language], t(pathnameLastElem));

            pageTitle = pluralName?.[language] || metaMenuName || t(pathnameLastElem);
            // console.log(pageTitle);

            setSelectedLanguage(language);

            const oldRoute = {
                pathname,
                search,
                state
            };

            const newRoute = {
                pathname,
                search,
                state: state
                    ? {
                          ...state,
                          pageTitle: state?.pageTitle?.includes(`(${t('filtered')})`)
                              ? `${pageTitle} (${t('filtered')})`
                              : pageTitle
                      }
                    : { pageTitle }
            };

            if (routeStore.findIndex(newRoute) === -1) {
                routeStore.replace(oldRoute, newRoute);
                oldNavigate(
                    { pathname, search },
                    {
                        state: state
                            ? {
                                  ...state,
                                  pageTitle:
                                      state?.pageTitle?.includes('(') &&
                                      state?.pageTitle?.includes(')')
                                          ? `${pageTitle} (${t('filtered')})`
                                          : pageTitle
                              }
                            : { pageTitle }
                    }
                );
            }
        }
    }, [
        id,
        isDetailPage,
        isHomePage,
        language,
        oldNavigate,
        pathname,
        pathnameLastElem,
        search,
        selectedLanguage,
        state,
        t
    ]);

    const statePageTitle = state ? state.pageTitle : undefined;

    const pageTitle = statePageTitle || t(pathnameLastElem);

    return (
        <CommonPageContext.Provider
            value={{
                getClosePageHandler
            }}
        >
            <PageHeader
                title={t(pageTitle)}
                disabled={isHomePage}
                onVertical={900}
                right={
                    <ButtonWithTooltips
                        id="close"
                        type="text"
                        className=""
                        onClick={getClosePageHandler(location, navigate, dropScope)}
                        tooltipTitle={t('close')}
                        icon={<CloseOutlined />}
                    />
                }
            />
            {children}
        </CommonPageContext.Provider>
    );
});
