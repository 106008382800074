import {
    Badge,
    Button,
    Collapse,
    CollapseProps,
    ConfigProvider,
    Dropdown,
    Flex,
    Input,
    Space,
    Table,
    Tooltip,
    Typography
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import * as JsSearch from 'js-search';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';

import { useMemo, useRef, useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { useCounter, useEffectOnce, useList, useUpdateEffect } from 'react-use';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { StoreLink } from 'ui';
import { LocalSearchComponent } from 'smart/modules/SmartTablePage/components/LocalSearchComponent/LocalSearchComponent';
import { v4 } from 'uuid';
import { fnv1aHash } from 'smart/utils';
import { toJS } from 'mobx';
import { isArray, isDefined, isObject } from 'is-lite/exports';
import { IObjectWithId } from 'utils/store/MetaStore';
import { SmartTooltip } from 'smart/ui';

interface IModalLogMessages {
    logMessages: LogMessage[] | undefined;
    showAlways?: boolean;
    hardDuration?: number;
}
const sorter = (a: LogMessage, b: LogMessage, column: keyof LogMessage) => {
    if (typeof a[column] === 'string' && typeof b[column] === 'string')
        return a[column].localeCompare(b[column]);
    if (Array.isArray(a[column] && Array.isArray(b[column]))) {
        if (typeof a[column].at(0) === 'string' && typeof b[column].at(0) === 'string') {
            return (a[column] as string[])
                .join(',')
                .localeCompare((b[column] as string[]).join(','));
        }

        return a[column].length - b[column].length;
    }

    return 0;
};

const transformArray = (inputArray: any[]) => {
    // Создаем объект для хранения промежуточных данных
    const metaMap = {} as {
        [key: string]: {
            Id: string;
            Meta: string;
            children: { Link: React.ReactNode; Type: React.ReactNode; Id: React.ReactNode }[];
        };
    };

    // Проходим по каждому элементу входного массива
    inputArray.forEach((item) => {
        const { Id, Meta, Link, Type, MetaCode } = item;

        // Если ключ Meta уже существует в metaMap, добавляем в его children новый объект
        if (metaMap[MetaCode]) {
            metaMap[MetaCode].children.push({ Id, Link, Type });
        } else {
            // Иначе создаем новый ключ и добавляем первый элемент в его children
            metaMap[MetaCode] = {
                Id: v4(),
                Meta,
                children: [{ Id, Link, Type }]
            };
        }
    });

    // Преобразуем объект metaMap обратно в массив
    return Object.values(metaMap);
};

export const LogMessagesTable: React.FC<IModalLogMessages> = ({
    showAlways,
    logMessages = [],
    hardDuration
}) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    if (logMessages.length === 0 && !showAlways) return null;

    const [activeKeys, { set: setActiveKeys }] = useList<string>(['logs']);
    const [localSearchFilter, setLocalSearchFilter] = useState('');

    // const isBothOpen = activeKeys.length === 2;

    const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null);

    const logs = useMemo(
        () => logMessages.filter((item) => !item.Categories?.includes('OBJECT_CREATED')),
        [logMessages]
    );

    // console.log(logs);

    const results = useMemo(
        () =>
            transformArray(
                logMessages
                    .filter((item) => item.Categories?.includes('OBJECT_CREATED'))
                    .map((item, _, items) => {
                        const Id = item.Fields?.Object?.Id;
                        const MetaCode = item.Fields?.Meta?.Code;
                        const pluralName = item.Fields?.Meta?.PluralName?.[language];
                        const singularName = item.Fields?.Meta?.SingularName?.[language];
                        const uiAllowView = item.Fields?.Meta?.UiAllowView ?? true;
                        const uiAllowSelect = item.Fields?.Meta?.UiAllowSelect ?? true;
                        const tablePathname = `/other/${MetaCode}`;
                        const detailPathname = `/other/${MetaCode}/${Id}`;

                        const tablePageTitle = `${pluralName || MetaCode} (${t('filtered')})`;

                        const ids = items
                            .filter((i) => i.Fields?.Meta?.Code === item.Fields?.Meta?.Code)
                            .map((i) => i.Fields?.Object?.Id);

                        const filterString = `Id=in.[${ids.join(',')}]`;

                        const cacheKey = fnv1aHash(`${tablePathname}_filtered_${filterString}`);

                        return {
                            Id,
                            MetaCode,
                            Meta: uiAllowSelect ? (
                                <StoreLink
                                    style={{ padding: 0 }}
                                    to={tablePathname}
                                    state={{
                                        pageTitle: tablePageTitle,
                                        filterString,
                                        cacheKey
                                    }}
                                >
                                    {singularName || MetaCode}
                                </StoreLink>
                            ) : (
                                singularName || MetaCode
                            ),
                            Link: uiAllowView ? (
                                <StoreLink
                                    style={{ padding: 0 }}
                                    to={detailPathname}
                                    state={{
                                        data: item.Fields?.Object
                                    }}
                                >
                                    {item.Fields?.Object?.Name?.[language] ||
                                        item.Fields?.Object?.Key ||
                                        item.Fields?.Object?.Code}
                                </StoreLink>
                            ) : (
                                item.Fields?.Object?.Name?.[language] ||
                                item.Fields?.Object?.Key ||
                                item.Fields?.Object?.Code
                            ),
                            Type: item.Fields?.Object?.Type_Code
                            // Type: (
                            //     <StoreLink style={{ padding: 0 }} to={item.Fields?.Object?.Type?.Id}>
                            //         {item.Fields?.Object?.Type?.Name ||
                            //             item.Fields?.Object?.Type?.Key ||
                            //             item.Fields?.Object?.Type?.Code}
                            //     </StoreLink>
                            // )
                        };
                    })
            ) as any[],
        [language, logMessages, t]
    );

    // console.log(results, logs);
    // console.log(logMessages);

    const resultColumns = useMemo(
        () => [
            {
                title: 'Id',
                dataIndex: 'Id',
                key: 'Id',
                width: 50,
                render: () => <></> // hide content
                // hidden: true
            },
            {
                title: 'MetaCode',
                dataIndex: 'MetaCode',
                key: 'MetaCode',
                width: '33.3%',
                hidden: true // service column
            },
            {
                title: 'Meta',
                dataIndex: 'Meta',
                key: 'Meta',
                width: '33.3%'
            },
            {
                title: 'Link',
                dataIndex: 'Link',
                key: 'Link',
                width: '33.3%'
            },
            {
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                width: '33.3%'
            }
        ],
        []
    );

    const logColumns = useMemo<ColumnsType>(
        () => [
            {
                title: t('id'),
                dataindex: 'Id',
                key: 'Id',
                hidden: true
            },
            // {
            //     title: null,
            //     dataindex: 'CurrentPath',
            //     key: 'CurrentPath',
            //     render: () => null,
            //     width: 85
            //     // hidden: true
            // },
            // {
            //     title: t('path'),
            //     dataindex: 'CurrentPath',
            //     key: 'CurrentPath',
            //     render: (value) => {
            //         // console.log(value?.CurrentPath);
            //         return (
            //             <SmartTooltip>
            //                 {isObject(value as IObjectWithId) ? value?.CurrentPath : value}
            //             </SmartTooltip>
            //         );
            //     },
            //     width: 200
            //     // hidden: true
            // },
            {
                title: t('message'),
                dataIndex: 'Message',
                key: 'Message',
                width: '75%',
                render: (value, row) => {
                    const title = `${
                        isObject(row.CurrentPath)
                            ? `${row.CurrentPath?.CurrentPath}`
                            : `${row.CurrentPath}`
                    } ${value ?? ''}`;

                    const showTooltip = title.length > 110;

                    const getColor = () => {
                        switch (row.Level) {
                            case 'debug':
                                return '#33658A';
                            case 'info':
                                return '#86BBD8';
                            case 'warn':
                                return '#F6AE2D';
                            case 'error':
                                return '#F26419';
                            case 'dpanic':
                                return '#F22E18';
                            case 'panic':
                                return '#F22E18';
                            case 'fatal':
                                return '#F22E18';
                            default:
                                return undefined; // Default logging level
                        }
                    };

                    return (
                        <Flex gap={10}>
                            <Tooltip title={row.Level} color={getColor()} destroyTooltipOnHide>
                                <Badge color={getColor()} />
                            </Tooltip>
                            <Tooltip
                                trigger="click"
                                open={showTooltip ? undefined : false}
                                title={title}
                                overlayStyle={{ maxWidth: 500 }}
                                color={getColor()}
                                destroyTooltipOnHide
                            >
                                <Highlighter
                                    style={{ display: 'block' }}
                                    searchWords={[localSearchFilter]}
                                    autoEscape
                                    // textToHighlight={value ?? ''}
                                    textToHighlight={
                                        showTooltip ? `${title.slice(0, 110)}...` : title
                                    }
                                    // textToHighlight={title}
                                />
                            </Tooltip>
                        </Flex>
                    );
                    // if (value.length > 100) {
                    //     return (
                    //         <Dropdown
                    //             destroyPopupOnHide
                    //             menu={{
                    //                 items: [
                    //                     {
                    //                         label: t('copy'),
                    //                         icon: <CopyOutlined />,
                    //                         key: 'copy',
                    //                         onClick: () => {
                    //                             navigator.clipboard.writeText(value);
                    //                         }
                    //                     }
                    //                 ]
                    //             }}
                    //             trigger={['contextMenu']}
                    //         >
                    //             <Highlighter
                    //                 style={{ display: 'block' }}
                    //                 searchWords={[localSearchFilter]}
                    //                 autoEscape
                    //                 textToHighlight={`${
                    //                     isObject(row.CurrentPath)
                    //                         ? `${row.CurrentPath?.CurrentPath}`
                    //                         : `${row.CurrentPath}`
                    //                 } ${value.slice(0, 100)}...`}
                    //             />
                    //             {/* <div>{`${value.slice(0, 300)}...`}</div> */}
                    //         </Dropdown>
                    //     );
                    // }

                    // return (
                    //     <Highlighter
                    //         style={{ display: 'block' }}
                    //         searchWords={[localSearchFilter]}
                    //         autoEscape
                    //         // textToHighlight={value ?? ''}
                    //         textToHighlight={`${
                    //             isObject(row.CurrentPath)
                    //                 ? `${row.CurrentPath?.CurrentPath}`
                    //                 : `${row.CurrentPath}`
                    //         } ${value ?? ''}`}
                    //     />
                    // );
                }
            },
            {
                title: t('categories'),
                dataIndex: 'Categories',
                key: 'Categories',
                width: '13%',
                sorter: (a, b) => sorter(a, b, 'Categories'),
                render: (value) => {
                    // console.log('Categories', value);
                    return (
                        <Highlighter
                            searchWords={[localSearchFilter]}
                            autoEscape
                            textToHighlight={isArray(value) ? value.join(', ') : value ?? ''}
                        />
                    );
                }
            },
            // {
            //     title: t('level'),
            //     dataIndex: 'Level',
            //     key: 'Level',
            //     width: '12%',
            //     sorter: (a, b) => sorter(a, b, 'Level'),
            //     render: (value) => {
            //         // console.log('Level', value);
            //         return (
            //             <Highlighter
            //                 searchWords={[localSearchFilter]}
            //                 autoEscape
            //                 textToHighlight={value ?? ''}
            //             />
            //         );
            //     }
            // },
            {
                title: t('timestamp'),
                dataIndex: 'Timestamp',
                key: 'Timestamp',
                width: '12%',
                sorter: (a, b) => sorter(a, b, 'Timestamp'),
                render: (value) => {
                    // console.log('Timestamp', value);
                    return (
                        <Highlighter
                            searchWords={[localSearchFilter]}
                            autoEscape
                            textToHighlight={value ? dayjs(value).format('HH:mm:ss') : ''}
                        />
                    );
                }
            }
        ],
        [t, localSearchFilter]
    );

    const searchedLogs = useMemo(() => {
        if (localSearchFilter) {
            const index = new JsSearch.Search('Id');
            index.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

            // logColumns.forEach((item) => {
            //     index.addIndex(item.key as string);
            // });

            index.addIndex('Categories');
            index.addIndex('CurrentPath');
            index.addIndex(['CurrentPath', 'CurrentPath']);
            index.addIndex('Message');
            // index.addIndex('Level');
            index.addIndex('Timestamp');

            index.addDocuments(
                logs.map((d) => ({ ...d, Parent: undefined, children: undefined })) || []
            );

            const result = index.search(localSearchFilter) as LogMessage[];

            const map = {} as { [k: string]: LogMessage };
            for (const item of result) {
                map[item.Id] = item;
            }

            const sortedResult = [];
            for (const item of logs) {
                if (map[item.Id] !== undefined) {
                    sortedResult.push(map[item.Id]);
                }
            }

            return sortedResult;
        }

        return logs || [];
    }, [localSearchFilter, logs]);

    // console.log(logs, searchedLogs);

    const firstSearchedLog = searchedLogs.at(0);
    const searchedLogsKeys = searchedLogs.map((log) => log.Id);

    const resultLength = searchedLogs.length;
    const [currentResult, { inc, dec, set, reset }] = useCounter(1, resultLength);

    // const toScrollIndex = logs.findIndex((log) =>
    //     isEqual({ ...log, Parent: undefined, children: undefined }, firstSearchedLog)
    // );

    // const tableRef = useRef();

    useEffectOnce(() => {
        tblRef.current?.scrollTo({ index: 0 });
        reset();
    });

    useUpdateEffect(() => {
        // console.log(toJS(tblRef.current?.scrollTo({ key: firstSearchedLog.Id })));
        // console.log(toScrollIndex, firstSearchedLog, tblRef, tblRef.current?.nativeElement);
        // tblRef.current?.scrollTo({ index: toScrollIndex });
        if (firstSearchedLog) {
            // tblRef.current?.scrollTo({ key: firstSearchedLog?.Id });
            tblRef.current?.scrollTo({ key: searchedLogsKeys?.[currentResult - 1] });
        }
        // }, [firstSearchedLog]);
    }, [resultLength, currentResult]);

    const SEARCH_SIZE = 'middle';

    const collapseItems = useMemo<NonNullable<CollapseProps['items']>>(() => {
        const items = [
            {
                key: 'logs',
                label: <strong>{t('logs')}</strong>,
                children: (
                    <Table
                        scroll={{
                            // y: window.innerHeight / (isBothOpen ? 4.1 : 2.05),
                            y: window.innerHeight / 2.05,
                            x: 750
                        }}
                        pagination={false}
                        rowKey="Id"
                        ref={tblRef}
                        bordered
                        columns={logColumns}
                        dataSource={logs}
                        expandable={{
                            defaultExpandAllRows: true,
                            indentSize: 10
                        }}
                        virtual
                        showSorterTooltip={false}
                        title={
                            logMessages?.length
                                ? () => (
                                      <Flex justify="end" style={{ width: '100%' }}>
                                          <Space.Compact size={SEARCH_SIZE}>
                                              {localSearchFilter ? (
                                                  <Space.Compact
                                                      size={SEARCH_SIZE}
                                                      direction="horizontal"
                                                  >
                                                      <Button
                                                          onClick={() => {
                                                              if (currentResult === 1)
                                                                  set(resultLength);
                                                              else dec();
                                                          }}
                                                          disabled={resultLength === 0}
                                                      >
                                                          {'<'}
                                                      </Button>
                                                      <Input
                                                          style={{
                                                              width:
                                                                  50 +
                                                                  (String(resultLength).length -
                                                                      1) *
                                                                      17,
                                                              paddingLeft: 5,
                                                              paddingRight: 5,
                                                              textAlign: 'center'
                                                          }}
                                                          value={`${
                                                              resultLength > 0
                                                                  ? currentResult
                                                                  : resultLength
                                                          } ${t('of')} ${resultLength}`}
                                                          readOnly
                                                      />
                                                      <Button
                                                          onClick={() => {
                                                              if (currentResult === resultLength)
                                                                  reset();
                                                              else inc();
                                                          }}
                                                          disabled={resultLength === 0}
                                                      >
                                                          {'>'}
                                                      </Button>
                                                  </Space.Compact>
                                              ) : null}
                                              <LocalSearchComponent
                                                  searchFilter={localSearchFilter}
                                                  setFilter={setLocalSearchFilter}
                                                  placeholder={`${t('search')}...`}
                                              />
                                          </Space.Compact>
                                      </Flex>
                                  )
                                : undefined
                        }
                        summary={
                            logMessages?.length
                                ? () => (
                                      <Table.Summary fixed>
                                          <Table.Summary.Row>
                                              {/* <Table.Summary.Cell index={0}></Table.Summary.Cell> */}
                                              {/* <Table.Summary.Cell index={1}></Table.Summary.Cell> */}
                                              {/* <Table.Summary.Cell index={2}></Table.Summary.Cell> */}
                                              <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                              <Table.Summary.Cell index={4}>
                                                  <Typography.Text style={{ padding: 0 }} strong>
                                                      {t('total')}:
                                                  </Typography.Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={5}>
                                                  {(hardDuration
                                                      ? hardDuration * 100
                                                      : Number(
                                                            logMessages.at(-1)?.Fields?.elapsed
                                                        ) / 1000000
                                                  ).toFixed(2)}
                                                  ms
                                              </Table.Summary.Cell>
                                          </Table.Summary.Row>
                                      </Table.Summary>
                                  )
                                : undefined
                        }
                        // tableLayout="auto"
                    />
                )
            }
        ];

        if (results?.length)
            items.unshift({
                key: 'results',
                label: <strong>{t('results')}</strong>,
                children: (
                    <Table
                        showHeader={false}
                        scroll={{
                            y: window.innerHeight / 2.05,
                            // y: window.innerHeight / (isBothOpen ? 4.1 : 2.05),
                            x: 750
                        }}
                        pagination={false}
                        rowKey="Id"
                        ref={tblRef}
                        bordered
                        columns={resultColumns}
                        dataSource={results}
                        virtual
                        showSorterTooltip={false}
                    />
                )
            });

        return items;
    }, [
        t,
        logColumns,
        logs,
        logMessages,
        results,
        resultColumns,
        localSearchFilter,
        resultLength,
        currentResult,
        set,
        dec,
        reset,
        inc,
        hardDuration
    ]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellPaddingBlock: 8
                    }
                }
            }}
        >
            {collapseItems.length > 1 ? (
                <Collapse
                    size="small"
                    activeKey={activeKeys}
                    onChange={(keys) => setActiveKeys(typeof keys === 'string' ? [keys] : keys)}
                    style={{ maxHeight: '85vh', overflow: 'auto' }}
                    items={collapseItems}
                />
            ) : (
                collapseItems?.at(0)?.children || null
            )}
        </ConfigProvider>
    );
};
