import { AutoComplete, Flex, Input, SelectProps, Typography } from 'antd';
import { toJS } from 'mobx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useList } from 'react-use';

import { fnv1aHash } from 'smart/utils';
import { useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';

const Title: React.FC<Readonly<{ title?: string }>> = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
        {/* <a
            aria-disabled
            href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
        >
            more
        </a> */}
    </Flex>
);

const { Text } = Typography;

type AppSearchOptionType = NonNullable<SelectProps['options']>[number] & {
    valueForSearch?: string;
};

export const AppSearch = () => {
    const {
        i18n: { language }
    } = useTranslation();
    const storeNavigate = useStoreNavigate();

    const metaMenus = toJS(metaStore.meta.get('all')?.menu);
    const metaMetas = toJS(metaStore.meta.get('InfoMeta')?.select)?.objects;

    const metaOptions: AppSearchOptionType[] =
        metaMetas
            ?.map((m) => ({
                value: m?.Id,
                valueForSearch: m?.PluralName?.[language] || m?.Code,
                label: (
                    <Text
                        style={{ padding: 0 }}
                        onClick={() =>
                            storeNavigate(
                                { pathname: `/other/${m?.Code}` },
                                {
                                    state: {
                                        // filterString,
                                        cacheKey: fnv1aHash(
                                            `${m?.Id}_${m?.PluralName?.[language]}`
                                        ),
                                        pageTitle: m?.PluralName?.[language] || m?.Code
                                    }
                                }
                            )
                        }
                    >
                        {m?.PluralName?.[language] || m?.Code}
                    </Text>
                )
            }))
            .sort(
                (a, b) =>
                    (a.valueForSearch as string)?.localeCompare(
                        b.valueForSearch ?? '',
                        language === 'tech' ? 'en' : language //если tech то падает
                    ) ?? 0
            ) ?? [];

    const menuOptions: AppSearchOptionType[] =
        metaMenus?.items
            .filter((m) => !m.is_hidden && !m.is_folder)
            ?.map((m) => {
                const label = m.name?.[language];
                const [pathname, filterString] = m.path.split('?');
                const cacheKey = fnv1aHash(`${m.id}_${label}`);

                return {
                    value: m.id,
                    valueForSearch: m.name?.[language],
                    label: (
                        <Text
                            style={{ padding: 0 }}
                            onClick={() =>
                                storeNavigate(
                                    { pathname },
                                    {
                                        state: {
                                            filterString,
                                            cacheKey,
                                            pageTitle: label
                                        }
                                    }
                                )
                            }
                        >
                            {label}
                        </Text>
                    )
                };
            })
            .sort(
                (a, b) =>
                    (a.valueForSearch as string)?.localeCompare(
                        b.valueForSearch ?? '',
                        language === 'tech' ? 'en' : language
                    ) ?? 0
            ) ?? [];

    const initialOptions: AppSearchOptionType[] = [
        {
            label: <Title title="Menu" />,
            options: menuOptions
        },
        {
            label: <Title title="Meta" />,
            options: metaOptions
        }
        // {
        //     label: <Title title="By context" />,
        //     options: []
        // }
    ];

    const [options, optionsMake] = useList<AppSearchOptionType>(initialOptions);
    const [selectedOption, setSelectedOption] = useState<AppSearchOptionType>();

    const handleSearch = (search: string) => {
        if (search) {
            optionsMake.set(() =>
                initialOptions.map((o) => ({
                    ...o,
                    options: o.options?.filter((oc: AppSearchOptionType) =>
                        oc.valueForSearch?.toLowerCase()?.includes(search?.toLowerCase())
                    )
                }))
            );
        } else {
            optionsMake.set(initialOptions);
        }
    };

    return (
        <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={500}
            style={{ width: 250 }}
            options={options}
            value={selectedOption?.valueForSearch}
            onChange={(value, option) => setSelectedOption(option)}
            onSearch={handleSearch}
        >
            {language === 'ru' ? (
                <Input.Search placeholder="Поиск по IT Vectura..." />
            ) : (
                <Input.Search placeholder="Search in IT Vectura..." />
            )}
        </AutoComplete>
    );
};
