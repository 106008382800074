import { Form, Dropdown, MenuProps } from 'antd';
import { useEffect, useState } from 'react';

import { EditableContext } from '../../contexts';

interface EditableRowProps {
    index: number;
    row: any;
    onDoubleClick: () => void;
    onCtrlClick: () => void;
    onShiftClick: () => void;
    onClick: () => void;
    onRightClick: () => void;
    virtual?: boolean;
    style?: React.CSSProperties;
    className?: string;
    contextMenuItems?: MenuProps['items'];
}

export const EditableRow: React.FC<EditableRowProps> = ({
    index,
    row,
    virtual,
    onDoubleClick,
    onCtrlClick,
    onShiftClick,
    onClick,
    onRightClick,
    contextMenuItems,
    ...props
}) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        form.setFieldsValue(row);
        setIsLoading(false);
    }, [form, row]);

    const RowContent = (
        <tr
            {...props}
            onDoubleClick={onDoubleClick}
            onClick={(e) => {
                if (e.shiftKey) {
                    onShiftClick();
                } else if (e.ctrlKey) {
                    onCtrlClick();
                } else {
                    onClick();
                }
            }}
            style={{
                ...props.style,
                display: virtual || props.className?.includes('placeholder') ? 'flex' : 'table-row',
                background: row?.isHardGroup ? 'rgba(0,0,0,.02)' : undefined
            }}
        />
    );

    if (props.className?.includes('placeholder') || !contextMenuItems) {
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={{ form, initialized: !isLoading }}>
                    {RowContent}
                </EditableContext.Provider>
            </Form>
        );
    }

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={{ form, initialized: !isLoading }}>
                <Dropdown
                    onOpenChange={onRightClick}
                    trigger={['contextMenu']}
                    menu={{ items: contextMenuItems }}
                    destroyPopupOnHide
                >
                    {RowContent}
                </Dropdown>
            </EditableContext.Provider>
        </Form>
    );
};
