import { EyeOutlined, MonitorOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { toJS } from 'mobx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';

import { useLogsModal } from 'App';
import { metaStore } from 'utils/store/MetaStore';
import { observer } from 'mobx-react-lite';
// import { LogMessagesModal } from '../LogMessagesModal/LogMessagesModal';

export const LogsField = observer<{
    requestId: string;
    btnSize?: SizeType;
    hardDuration?: number;
    onClick?: (e?: React.SyntheticEvent) => void;
    onClose?: (e?: React.SyntheticEvent) => void;
}>(({ requestId, btnSize, hardDuration, onClick, onClose }) => {
    const { t } = useTranslation();
    const [logModalState, setLogModalState] = useLogsModal();

    // const [openModal, setOpenModal] = useBoolean(false);

    const [logs, fetchLogs] = useAsyncFn(async () => {
        // if (logModalState.open) {
        const res = await metaStore.getLogs({ request_id: requestId });

        return toJS(res?.log_messages);
        // }

        // return metaStore.meta.get('all')?.logs?.[requestId]?.log_messages;
    }, [requestId]);

    useEffect(() => {
        setLogModalState((prev) => ({
            ...prev,
            logMessages: logs?.value ?? [],
            onCancel: (e) => {
                console.log('cancel');
                setLogModalState((prev) => ({ ...prev, open: false }));
                if (onClose) onClose(e);
            },
            hardDuration
        }));
    }, [hardDuration, logs?.value, onClose, setLogModalState]);

    return (
        <>
            <Button
                size={btnSize}
                // icon={<EyeOutlined />}
                icon={<MonitorOutlined />}
                onClick={async (e) => {
                    e.stopPropagation();
                    // setOpenModal(true);
                    await fetchLogs();
                    setLogModalState((prev) => ({ ...prev, open: true }));

                    if (onClick) onClick(e);
                }}
            >
                {t('logs')}
            </Button>

            {/* <LogMessagesModal
                logMessages={openModal ? logs?.value : []} // open/no-open
                onCancel={(e) => {
                    setOpenModal(false);
                    if (onClose) onClose(e);
                }}
                hardDuration={hardDuration}
            /> */}
        </>
    );
});
